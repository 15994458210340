import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { removeLastSlash } from './Lib/utils'
import dayjs from "dayjs"
import TicketsTypeGuestsStats from "./TicketsTypeGuestsStats"
import { CloseButton, Modal } from "./Modal"
import Button from './Button'

const HeaderStats = ({ stats, page, pathname, modelName, role }) => {
  const [showTicketsTypeStats, setShowTicketsTypeStats] = useState(false)

  if (!Object.keys(stats).length) return <></>

  const GeneralStats = ({ viewTicketTypeStats }) => {
    return (
      <div className="flex w-full overflow-x-auto bg-white" style={{ minHeight: "160px" }}>
        {
          Object.values(stats).map((stat, index, array) => {
            const lastItemNumber = array.length - 1

            return (
              <div
                key={`header-item-${stat._id || index}`}
                className={` ${lastItemNumber === index ? "w-full" : "w-auto"} px-6 py-4 whitespace-nowrap`}>
                <div className="text-left bg-white">
                  <span className="relative uppercase wght-light text-xxs">
                    {stat.statTitle}
                  </span >
                </div>

                <div className="relative align-top bg-white">
                  <div className="flex flex-col">
                    {stat.title &&
                      <h2 className='wght-semibold'>
                        {stat.title}
                      </h2>
                    }

                    {stat.invitationsNumber >= 0 &&
                      <h2 className='wght-semibold'>
                        {`${stat.invitationsNumber} invitations`}
                      </h2>
                    }

                    {stat.changedTickets >= 0 &&
                      <h2 className='wght-semibold'>
                        {`${stat.changedTickets} tickets`}
                      </h2>
                    }

                    {stat.noChangedTickets >= 0 &&
                      <h2 className='wght-semibold'>
                        {`${stat.noChangedTickets} tickets`}
                      </h2>
                    }

                    {stat.ticketsUsed >= 0 &&
                      <h2 className='wght-semibold'>
                        {`${stat.ticketsUsed} tickets`}
                      </h2>
                    }

                    {(stat.startDate && stat.startDate) &&
                      <span className="text-xs wght-light">
                        {`From ${dayjs(stat.startDate).format('DD/MM/YYYY')} to ${dayjs(stat.endDate).format('DD/MM/YYYY')}`}
                      </span>
                    }

                    {(stat.modelName && stat.invitationsCreated >= 0 && stat.invitationsNumber >= 0) &&
                      <span className="text-xs wght-light">
                        {`${stat.invitationsCreated} of ${stat.invitationsNumber} assigned`}
                      </span>
                    }

                    {(stat.modelName && stat.invitationsSent >= 0 && stat.invitationsCreated >= 0) &&
                      <span className="text-xs wght-light">
                        {`${stat.invitationsSent} of ${stat.invitationsCreated} sent`}
                      </span>
                    }

                    {(!stat.modelName && stat.invitationsSent >= 0 && stat.invitationsCreated >= 0) &&
                      <span className="text-xs wght-light">
                        {`${stat.percentInvitationsSent} % sent`}
                      </span>
                    }

                    {stat.percentTicketsChanged >= 0 &&
                      <span className="text-xs wght-light">
                        {`${stat.percentTicketsChanged} %`}
                      </span>
                    }

                    {stat.ticketsPendingChange >= 0 &&
                      <span className="text-xs wght-light">
                        {`${stat.ticketsPendingChange} pending`}
                      </span>
                    }
                    {stat.pendingTicketsToUse >= 0 &&
                      <span className="text-xs wght-light">
                        {`${stat.pendingTicketsToUse} pending`}
                      </span>
                    }

                    {stat.percentTicketsNoChanged >= 0 &&
                      <span className="text-xs wght-light">
                        {`${stat.percentTicketsNoChanged} %`}
                      </span>
                    }

                    {stat.percentTicketsUsed >= 0 && stat.ticketsUsed > 0 && stat.totalTickets > 0 &&
                      <span className="text-xs wght-light">
                        {`${Math.round((stat.ticketsUsed / stat.totalTickets) * 10000) / 100} %`}
                      </span>
                    }

                    {
                      role === 'admin' && (stat.modelName && stat.modelName !== 'Event' && stat._id) &&
                      <Link className="text-xs cursor-pointer text-primary hover:wght-semibold"
                        to={{ pathname: `${removeLastSlash(pathname)}/edit/${stat.modelName}/${stat._id}`, state: { page } }}
                      >
                        Modify {stat.statTitle.replace('total', '')}
                      </Link>
                    }
                  </div>
                </div>

                {
                  (modelName === 'GuestsCategory' || (modelName === 'GuestsInvitation' && !stats.entity)) && viewTicketTypeStats && lastItemNumber === index &&
                  <div className="flex w-full pt-4">
                    <div className="flex justify-end w-full">
                      <Button size="small" onClick={() => setShowTicketsTypeStats(state => !state)}
                      >View Stats</Button>
                    </div>
                  </div>
                }
              </div >
            )
          })
        }
      </div>
    )
  }

  return (
    <div className="mb-4 bg-white">
      <GeneralStats viewTicketTypeStats />

      <Modal showModal={showTicketsTypeStats} setShowModal={setShowTicketsTypeStats}>
        <div className="relative w-full pb-0 mx-12 overflow-auto bg-white z-1 max-h-128">
          <div className="px-4">
            <CloseButton setShowModal={setShowTicketsTypeStats} />
            <div className="mt-8">
              <GeneralStats />
              <TicketsTypeGuestsStats modelName={modelName} />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default HeaderStats