import { gql } from 'graphql-request'

export const SendMessageToTicket = gql`
  mutation SendMessageToTicket($ticketId: String!, $message: MessageInput!) {
    sendMessageToTicket(ticketId: $ticketId, message: $message) {
      sent
    }
  }
`

export const ListControlDevices = gql`
  query ListControlDevices($events: [ID], $category: ID) {
    listControlDevices(events: $events, category: $category) {
      name
      deviceName
      batteryLevel
      batteryState
      updateDate 
      ticketId 
      zoneId
      zoneName
      deviceId
      nfcMode
      offlineCapable
      type
      freezed
      hexId
      category
      eventId
      allowToShowMetrics
      hideUserFunctions
      isAllowedToCancel
      randomMode
    }
  }
`

export const uploadManagerImageUrl = gql`
  mutation UploadManagerImageUrl($ticketId: String!, $addPhoto: Boolean) {
    uploadManagerImageUrl(ticketId: $ticketId, addPhoto: $addPhoto) {
      url
    }
  }
`

export const UpdateControlDeviceStatus = gql`
  mutation UpdateControlDeviceStatus($deviceId: ID, $ticketId: ID, $zoneId: ID, $nfcMode: Boolean, $type: String, $hexId: String, $category: ID, $offlineCapable: Boolean, $eventId: ID, $allowToShowMetrics: Boolean, $hideUserFunctions: Boolean, $isAllowedToCancel: Boolean, $randomMode: Boolean, $name: String, $deviceName: String) {
    updateControlDeviceStatus(deviceId: $deviceId, ticketId: $ticketId, zoneId: $zoneId, nfcMode: $nfcMode, type: $type, hexId: $hexId, category: $category, offlineCapable: $offlineCapable, eventId: $eventId, allowToShowMetrics: $allowToShowMetrics, hideUserFunctions: $hideUserFunctions, isAllowedToCancel: $isAllowedToCancel, randomMode: $randomMode, name: $name, deviceName: $deviceName) {
      name
      deviceName
      batteryLevel
      batteryState
      updateDate 
      ticketId 
      zoneId
      deviceId
      nfcMode
      offlineCapable
      type
      hexId
      category
      eventId
      allowToShowMetrics
      hideUserFunctions
      isAllowedToCancel
      randomMode
    }
  }
`

export const ListControlDeviceHistory = gql`
  query ListControlDeviceHistory($deviceId: ID, $limit: Int, $skip: Int) {
    listControlDeviceHistory(deviceId: $deviceId, limit: $limit, skip: $skip) {
      device {
        name 
        deviceId
        batteryLevel
        batteryState
        updateDate 
        ticketId 
        zoneId
      }	
      history {
        date
        userId
        userName
        type
        data
      }
    }
  }
`

export const ListControlDeviceLogs = gql`
  query LastControlDeviceLogs($deviceId: ID!) {
    lastControlDeviceLogs(deviceId: $deviceId) {
      date
      type 
      zoneId 
      ticketId
    }
  }
`

export const DeleteControlDevice = gql`
  mutation DeleteControlDevice($deviceId: ID) {
    deleteControlDevice(deviceId: $deviceId) {
      deleted	
    }
  }
`

export const KillControlDevice = gql`
  mutation KillControlDevice($deviceId: ID) {
    killControlDevice(deviceId: $deviceId)
  }
`

export const RefetchSumsubImage = gql`
  mutation refetchSumsubImage($ticketId: String!) {
    refetchSumsubImage(ticketId: $ticketId) {
      valid
      message
    }
  }
`

export const RefreshControlDeviceTicketTypes = gql`
  mutation RefreshControlDeviceTicketTypes($deviceId: ID) {
    refreshControlDeviceTicketTypes(deviceId: $deviceId) {
      deviceId	
    }
  }
`

export const RebootControlDevice = gql`
  mutation RebootControlDevice($deviceId: ID) {
    rebootControlDevice(deviceId: $deviceId) {
      deviceId	
    }
  }
`

export const ListControlDevicesCategories = gql`
  query ListControlDevicesCategories($event: ID) {
    listControlDevicesCategories(event: $event) {
      _id
      title	
    }
  }
`

export const DeleteUser = gql`
  mutation DeleteUser($userId: String!) {
    deleteUser(userId: $userId)
  }
`

export const DiceListTicketsManager = gql`
  query diceListTicketsManager($fanPhoneNumber: String!) {
    diceListTicketsManager(fanPhoneNumber: $fanPhoneNumber) {
      id
      title
      startDate
      endDate
      exchanged
      credit
    }
  }
`

export const DiceExchangeTicketsManager = gql`
  mutation diceExchangeTicketsManager($fanPhoneNumber: String!, $email: String!) {
    diceExchangeTicketsManager(fanPhoneNumber: $fanPhoneNumber, email: $email) {
      valid
    }
  }
`

export const TransferTicketManager = gql`
  mutation TransferTicketManager($publicId: ID!, $email: String!) {
    transferTicketManager(publicId: $publicId, email: $email) {
      valid
    }
  }
`

export const ListDiceTicketsForPhoneNumber = gql`
  query ListDiceTicketsForPhoneNumber($phoneNumber: String!) {
    listDiceTicketsForPhoneNumber(phoneNumber: $phoneNumber) {
      title
      claimedAt 
      code
      holder {
        dob 
        email 
        firstName 
        lastName 
      }
      title 
      ticketType {
        name
      }
      exchanged
    }
  }
`

export const GetAccessCode = gql`
  query GetAccessCode($email: String!) {
    getAccessCode(email: $email) {
      code
    }
}`

export const RefundRedsysPurchase = gql`
  mutation refundRedsysPurchase($purchaseId: ID!, $reason: String!,  $amount: String!) {
    refundRedsysPurchase(purchaseId: $purchaseId, reason: $reason, amount: $amount) {
      message
    }
  }
`
export const refundRedsysMarketplacePurchase = gql`
  mutation refundRedsysMarketplacePurchase($ticketId: ID!,$orderId: String!,  $reason: String!) {
    refundRedsysMarketplacePurchase(ticketId: $ticketId, orderId: $orderId, reason: $reason) {
      message
    }
  }
`